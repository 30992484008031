exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-cooperation-js": () => import("./../../../src/pages/cooperation.js" /* webpackChunkName: "component---src-pages-cooperation-js" */),
  "component---src-pages-flyttstad-js": () => import("./../../../src/pages/flyttstad.js" /* webpackChunkName: "component---src-pages-flyttstad-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-job-js": () => import("./../../../src/pages/job.js" /* webpackChunkName: "component---src-pages-job-js" */),
  "component---src-pages-quality-and-environment-js": () => import("./../../../src/pages/quality-and-environment.js" /* webpackChunkName: "component---src-pages-quality-and-environment-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-synpunkter-js": () => import("./../../../src/pages/synpunkter.js" /* webpackChunkName: "component---src-pages-synpunkter-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

